export default formId => {
  const companyNameField = document.querySelector('#company-auth')
  if (!companyNameField) {
    return
  }
  const domainWrap = document.querySelector('.domain-check-wrap')
  const domainName = domainWrap.querySelector('#domain-status-auth')
  const progress = domainWrap.querySelector('.domain-status__existance')
  const progressText = progress.querySelector('span')
  const submitBtn = document.querySelector('button[type=submit]')
  const statusInput = $('#status')

  const enableButton = function() {
    $(formId).trigger('checkValidation')

    //submitBtn.classList.remove('auth-btn--disabled');
    //submitBtn.classList.add('auth-btn--green');
  }

  const disableButton = function() {
    submitBtn.classList.remove('auth-btn--green')
    submitBtn.classList.add('auth-btn--disabled')
  }

  const setAvailable = function() {
    statusInput.val('1')

    //click for trigger validate function
    statusInput.click()
    // change status text
    progressText.textContent = 'Beschikbaar'
    // show green bg on elem
    progress.classList.remove('error')
    progress.classList.add('success')
    domainWrap.classList.add('show-status')

    enableButton()
  }

  const setUnavailable = function() {
    statusInput.val('0')
    // change status text
    progressText.textContent = 'Niet beschikbaar'
    // show red bg on elem
    progress.classList.remove('success')
    progress.classList.add('error')
    // status.value = domainField.value;
    domainWrap.classList.add('show-status')

    disableButton()
  }

  const setChecking = function() {
    statusInput.val('0')
    domainWrap.classList.remove('show-status')
    disableButton()
  }

  const checkDomainAvailubility = () => {
    if (
      domainName.value.length < 3 ||
      domainName.value.length > 25 ||
      /^[a-zA-Z0-9]+$/i.test(domainName.value) === false
    ) {
      setChecking()
      return
    }

    setChecking()

    fetch('/signup/check_url/?domain=' + domainName.value, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success' && result.data.domain_available === true) {
          setAvailable()
          return
        }

        setUnavailable()
      })
  }
  $(formId).on('refill', checkDomainAvailubility)
  companyNameField.addEventListener('blur', () => {
    domainName.value = companyNameField.value.toLowerCase().replace(/[^0-9a-zA-Z]/g, '')
    checkDomainAvailubility()
  })
  domainName.addEventListener('change', checkDomainAvailubility)
}

import '../css/signup.scss'

import $ from 'jquery'
window.$ = window.jQuery = $

import { initGoogleAuth, handleGoogleResponse } from './_partials/sign-in/_googleSignIn'
import { initMicrosoftAuth } from './_partials/sign-in/_microsoftSignIn'
import sendSignInUserData from './_partials/sign-in/_sendFormData'
import createEnvironment from './_partials/sign-in/_enviromentCreation'
// import customSelect from './_partials/sign-in/_customSelect'
import checkDomain from './_partials/sign-in/_check-domain-field'
import getUserEmail from './_partials/sign-in/_getUserEmailAddress'
import formDataValidation from './_partials/sign-in/_formDataValidation'
import formRefill from './_partials/sign-in/_formRefill'
import passwordToggler from './_partials/sign-in/_passwordToggler'
import reframe from './_partials/reframe'
import fixScreenHeight from './_partials/sign-in/_fixScreenHeight'
import { KVKSearch } from './_partials/forms/KVKCheck'
import slimSelect from './_partials/_slim-select'
// import { toggleVisibility } from './_partials/redesign/forms/toggleVisibility'

window.handleResponse = (response) => {
    handleGoogleResponse(response)
}

window.addEventListener('DOMContentLoaded', () => {
    slimSelect()
    formRefill()
    reframe()
    fixScreenHeight()
    KVKSearch('[name="company_name"]')
    if (document.getElementById('user-step-1')) {
        initGoogleAuth()
        initMicrosoftAuth()
        formDataValidation('#user-step-1')
        sendSignInUserData('user-step-1', 'signup/domain_request/')
    } else if (document.getElementById('user-step-2')) {
        getUserEmail()
        formDataValidation('#user-step-2')
        sendSignInUserData('user-step-2', 'signup/domain_request/set_name/', 'PUT')
    } else if (document.getElementById('user-step-3')) {
        KVKSearch('[name="company_name"]', 'select[name="branch"]', 'select[name="team_size"]')
        passwordToggler()
        checkDomain('#user-step-3')
        formDataValidation('#user-step-3')
        sendSignInUserData('user-step-3', 'signup/domain_request/set_domain/', 'PUT')
    } else if (document.getElementById('user-step-4')) {
        formDataValidation('#user-step-4')
        // customSelect()
        // toggleVisibility()
        sendSignInUserData('user-step-4', 'signup/domain_request/set_additional_info/', 'PUT')
    } else if (document.getElementById('user-step-5')) {
        sendSignInUserData('user-step-5', 'signup/domain_request/send_invite/')
    } else if (document.getElementById('user-step-6')) {
        createEnvironment()
    }

    $('.authorization__steps-label a').on('click', function (e) {
      if (window.history.length > 1) {
        e.preventDefault()
        window.history.back()
      }
    })
})
